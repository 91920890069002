import * as React from "react";
import * as styles from './index.module.scss';




// markup
const Avatar = ({ size, image, }) => {
    return (
        <div className={styles.avatar} style={{
            width: `${size + 24}px`,
            height: `${size + 24}px`,
        }}>
            <div style={{
                backgroundImage: `url(${image})`,
                width: `${size}px`,
                height: `${size}px`,
                backgroundSize: 'cover',
                backgroundPosition: "center"
            }} />
        </div>
    )
}

export default Avatar
