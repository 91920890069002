import * as React from "react"
import Avatar from "../../Avatar";
import * as styles from './index.module.scss';


// markup
const PeopleCard = ({ name, image, role, description, cv, email, website }) => {
    return (
        <div className={`rounded d-md-flex p-4 bg-white mb-4`}>
            <div className="me-md-3 me-lg-4 mb-3 text-center text-md-start">
                <Avatar image={image} size={204} />
            </div>
            <div className="text-center text-md-start">
                <h2 className={styles.title}>{name}</h2>
                <p className="text-primary">{role}</p>
                <div className="people-bio" dangerouslySetInnerHTML={{ __html: description }} />
                <p>
                    <a className="d-inline-block me-3 text-decoration-none fw-semibold" href={cv} target="_blank" rel="noreferrer">View my CV</a> <span className="d-none d-md-inline-block fw-bold">&bull;</span>
                    <a className="d-inline-block mx-3 text-decoration-none fw-semibold" href={`mailto:${email}`}>Send me an email</a>
                    {website ?
                        <>
                            <span className="d-none d-md-inline-block fw-bold">&bull;</span>
                            <a className="d-inline-block ms-3 text-decoration-none fw-semibold" href={website} target="_blank" rel="noreferrer">Visit my website</a>
                        </>
                        : ""
                    }
                </p>
            </div>
        </div>
    )
}

export default PeopleCard;
