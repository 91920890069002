import * as React from "react"
import { graphql } from "gatsby"
import { Container } from "react-bootstrap"
import Footer from "../parts/Footer"
import Header from "../parts/Header"
import PeopleCard from "../components/people/PeopleCard"
import CustomHelmet from "../components/seo/CustomHelmet"

const People = ({ location, data }) => {
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname

  const { allMarkdownRemark } = data;
  const { nodes } = allMarkdownRemark;

  const peoples = nodes;

  return (
    <>
      <CustomHelmet title={"People"} canonicalUrl={canonicalUrl} />
      <Header activeKey="/people" />
      <main className="py-5" style={{ marginTop: '81px' }}>
        <Container>
          <h1 className="mb-4">People</h1>

          {peoples.map((people) => {
            const imagePath = people.frontmatter.photo && people.frontmatter.photo.childImageSharp 
              ? people.frontmatter.photo.childImageSharp.fluid.src 
              : 'path/to/default/image.jpg'; 

            return (
              <PeopleCard key={people.id}
                name={people.frontmatter.name}
                image={imagePath}
                role={people.frontmatter.role}
                description={people.html}
                cv={people.frontmatter.cv}
                email={people.frontmatter.email}
                website={people.frontmatter.website}
              />
            );
          })}

        </Container>
      </main>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
 {
    allMarkdownRemark(
        filter: {frontmatter: {taxonomy: {eq: "people"}}}
        sort: {fields: frontmatter___slug, order: ASC}
      ) {
        nodes {
          frontmatter {
            cv
            name
            role
            photo {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            email
            website
          }
          id
          html
        }
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
}
`

export default People;
